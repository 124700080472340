/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/apps/ticket-master/tm'
        icon='/media/icons/duotune/ecommerce/ecm003.svg'
        title='Ticket Master'
      />
      <AsideMenuItem
        to='/apps/imap-management/imap'
        icon='/media/icons/duotune/communication/com002.svg'
        title='IMAP'
      />
    </>
  )
}
