import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useEffect, useState } from 'react'
import {
  StatisticsWidget5,
} from '../../../_metronic/partials/widgets'
import { TablesWidget10 } from "../../../_metronic/partials/widgets/tables/TablesWidget10"
import axios from "axios"
interface statsModel {
  total_imap_count: number;
  total_tm_count: number;
  total_combo_count: number;
  total_valid_imap_count: number;
  total_valid_tm_count: number;
  total_valid_count: number;
  total_invalid_imap_count: number;
  total_invalid_tm_count: number;
  total_invalid_count: number;
  imap_hit_percentage: number;
  tm_hit_percentage: number;
  total_hit_percentage: number;
}
function DashboardPageFunc() {
  const [stats_data, setStatsData] = useState<statsModel>({
    total_imap_count: 0,
    total_tm_count: 0,
    total_combo_count: 0,
    total_valid_imap_count: 0,
    total_valid_tm_count: 0,
    total_valid_count: 0,
    total_invalid_imap_count: 0,
    total_invalid_tm_count: 0,
    total_invalid_count: 0,
    imap_hit_percentage: 0,
    tm_hit_percentage: 0,
    total_hit_percentage: 0,
  });
  const [stats_loading, setStatsLoading] = useState(true);
  const [stats_error, setStatsError] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://api.stellarticket.com/tm/api/combined_stats/stats/`);
        setStatsData(response.data);
        setStatsError(false);
      } catch (err) {
        setStatsError(true);
      } finally {
        setStatsLoading(false);
      }
    };
    getData();
  }, []);
  const DashboardPage = () => (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm003.svg'
            color='dark'
            iconColor='gray-13'
            title='Ticket Master Combos'
            description={stats_loading ? "..." : stats_data.total_tm_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/communication/com002.svg'
            color='dark'
            iconColor='gray-100'
            title='IMAP Combos'
            description={stats_loading ? "..." : stats_data.total_imap_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen003.svg'
            color='dark'
            iconColor='gray-100'
            title='Total Combos'
            description={stats_loading ? "..." : stats_data.total_combo_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm003.svg'
            color='success'
            iconColor='gray-100'
            title='Valid Ticket Master Combos'
            description={stats_loading ? "..." : stats_data.total_valid_tm_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/communication/com002.svg'
            color='success'
            iconColor='gray-100'
            title='Valid IMAP Combos'
            description={stats_loading ? "..." : stats_data.total_valid_imap_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen003.svg'
            color='success'
            iconColor='gray-100'
            title='Total Valid Combos'
            description={stats_loading ? "..." : stats_data.total_valid_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm003.svg'
            color='danger'
            iconColor='gray-100'
            title='Invalid Ticket Master Combos'
            description={stats_loading ? "..." : stats_data.total_invalid_tm_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/communication/com002.svg'
            color='danger'
            iconColor='gray-100'
            title='Invalid IMAP Combos'
            description={stats_loading ? "..." : stats_data.total_invalid_imap_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen003.svg'
            color='danger'
            iconColor='gray-100'
            title='Total Invalid Combos'
            description={stats_loading ? "..." : stats_data.total_invalid_count.toString()}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm003.svg'
            color='primary'
            iconColor='gray-100'
            title='Ticket Master Hit Percentage'
            description={stats_loading ? "..." : Math.round(stats_data.tm_hit_percentage) + "%"}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/communication/com002.svg'
            color='primary'
            iconColor='gray-100'
            title='IMAP Combos Hit Percentage'
            description={stats_loading ? "..." : Math.round(stats_data.imap_hit_percentage) + "%"}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen003.svg'
            color='primary'
            iconColor='gray-100'
            title='Total Hit Percentage'
            description={stats_loading ? "..." : Math.round(stats_data.total_hit_percentage) + "%"}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}
export { DashboardPageFunc }
