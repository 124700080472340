import { useAuth } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, Search } from '../../../partials'
import { Link } from 'react-router-dom'
/* eslint-disable jsx-a11y/anchor-is-valid */

const AsideToolbar = () => {
  const { currentUser, logout } = useAuth()

  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        {/*begin::Symbol*/}
        <div className='symbol symbol-50px'>
          <img src={toAbsoluteUrl('/media/avatars/profile.jpg')} alt='' />
        </div>
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2'>
              {/*begin::Username*/}
              <Link to='/dashboard' className='text-white text-hover-primary fs-6 fw-bold'>
                {currentUser?.first_name} {currentUser?.last_name}
              </Link>
              {/*end::Username*/}
              <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>{currentUser?.user_orgs && currentUser.user_orgs[0].name} Organization</span>
              {/*end::Description*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            <div className='me-n2'>
              {/*begin::Action*/}
              <a
                href='#'
                onClick={logout}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon-muted svg-icon-12'
                />
              </a>

              <HeaderUserMenu />
              {/*end::Action*/}
            </div>
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}
      {/*end::Aside search*/}
    </>
  )
}

export { AsideToolbar }
