/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useEffect, useState } from 'react'
import axios from "axios"
type Props = {
  className: string
}

function TablesWidget10(className: Props) {
  const [user_data, setUserData] = useState([]);
  const [user_loading, setUserLoading] = useState(true);
  const [user_error, setUserError] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://api.stellarticket.com/tm/api/get_user_stats/`);
        setUserData(response.data);
        setUserError(false);
      } catch (err) {
        setUserError(true);
      } finally {
        setUserLoading(false);
      }
    };
    getData();
  }, []);
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{!user_loading && user_data[0]["user_orgs"][0]["name"]} Organization</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{user_data.length} members</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Users</th>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {user_data &&
                user_data.map(({ id, username, first_name, last_name, email }) => (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl('/media/avatars/profile.jpg')} alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {username}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {email}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {first_name} {last_name}
                      </a>
                      <span className='text-success fw-semibold text-success d-block fs-7'>
                        Active User
                      </span>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget10 }